.page .things {
  padding: 0px 30px;
}

@media (min-width: 768px) {
  .page .things {
    padding: 0px 60px;
  }
}

.page .things .things-header {
  text-transform: uppercase;
  margin-bottom: 16px;
}

.page .things .grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: -8px;
  width: auto;
}
.page .things .grid .grid__column {
  padding-left: 16px;
  background-clip: padding-box;
}

.page .things .grid .grid__column > .thing {
  margin-bottom: 16px;
}
