.page,
body,
html {
  height: 100%;
}

.page .content p a,
.vertical-align {
  position: relative;
}

body {
  background: #f5efe6;
  font-family: "Roboto Mono", monospace;
  font-size: 0.8rem;
  line-height: 1.15rem;
  color: #283c46;
  margin: 0;
}

body .highlight-colour,
body a {
  color: #1b6ac9;
  text-decoration: none;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.page {
  padding: 0 15px 60px 15px;
  margin: auto;
}

.page .content {
  margin: 60px 0;
  padding: 15px 30px;
  border-left: 4px solid rgba(0, 0, 0, 0.1);
}

.page .content .avatar {
  width: 100px;
  height: 100px;
  border-radius: 2px;
  display: block;
  background-color: #fff;
  padding: 4px;
}

@media (min-width: 768px) {
  .page {
    max-width: 1111px;
  }
  .page .content {
    padding: 30px 60px;
  }
}

.page .content h1 {
  font-size: 2.8rem;
  font-weight: 700;
  line-height: 1.5;
  margin: 0 0 11px;
}

.page .content p {
  margin: 0;
  font-size: 1.5rem;
  line-height: 125%;
}

.page .content p a:hover {
  text-decoration: underline;
}

.page .content p a.open:hover,
.page .corner-ribbon,
.page .corner-ribbon:hover {
  text-decoration: none;
}

.page .content p a.open:after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid;
  top: 34px;
  margin-left: -5px;
  left: 50%;
}

.page .content .tabs {
  list-style: none;
  display: table;
  margin: 11px -8px 0 -8px;
  padding: 0;
}

.page .content .tabs .tab {
  float: left;
  margin: 8px;
}

.page .content .tabs .tab a {
  background-color: #fff;
  padding: 4px 8px;
  display: block;
}

.page .content .tabs .tab:hover {
  background-color: rgba(158, 158, 158, 0.2);
  border-radius: 4px;
}

.page .content .tabs .tab:hover > a {
  color: #283c46;
}

.page .content .tabs .tab > a.active {
  background-color: transparent;
  color: #283c46;
  border-bottom: 3px solid;
}
