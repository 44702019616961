.page .things .grid .thing {
  background-color: #fff;
}

.page .things:not(.web1) .grid .thing {
  transition: box-shadow 0.25s;
  border-radius: 2px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 2px 1px -1px rgba(0, 0, 0, 0.12);
}

.page .things:not(.web1) .grid .thing:hover {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.19), 0 3px 3px rgba(0, 0, 0, 0.23);
}

.page .things .grid .thing .thing-image {
  height: auto;
}

.page .things .grid .thing .thing-image img {
  display: block;
  border-radius: 2px 2px 0 0;
  position: relative;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  padding: 16px;
}

.page .things .grid .thing .thing-content {
  padding: 20px;
  border-radius: 0 0 2px 2px;
}

.page .things .grid .thing .thing-content .thing-title {
  font-size: 1.1rem;
  line-height: 1.15;
  font-weight: 300;
  margin-bottom: 11px;
  display: block;
  color: #283c46;
}

.page .things .grid .thing .thing-content p {
  color: #283c46;
  margin-bottom: 0;
  font-size: 0.8rem;
}

.page .things .grid .thing .thing-content .thing-skills {
  margin-top: 11px;
  display: block;
}

.page .things .grid .thing .thing-action {
  position: relative;
  border-top: 1px solid rgba(160, 160, 160, 0.2);
  font-size: 0.7rem;
  height: auto;
}

.page .things .grid .thing .thing-action a {
  text-transform: uppercase;
  display: block;
  padding: 20px;
  transition: all 0.3s ease;
}

.page .things .grid .thing .thing-action a:hover {
  background-color: rgba(158, 158, 158, 0.2);
  color: #283c46;
}
